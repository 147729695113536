import moment from 'moment'
import 'moment/min/locales'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import '../../assets/css/timeline.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import ConfigBusiness from "../../commons/config/ConfigBusiness.js"
import Security from '../../commons/security/Security.js'
import Loading from '../ui/Loading.js'
import backendStrings from '../../lang/backendStrings.js'

class TicketHistorico extends Component {
	constructor(props) {
    super(props);
    this.props = props;

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    if(props.ticket) {
      this.ticket = props.ticket;
    }
    
    this.state = {
      loading: false,
      historia: []
    }
  }

  componentDidMount() {
		if (Security.hasPermission('TICKETS_VER_HISTORIA')) {
      this.ajaxHandler.subscribe(this);
      this.loadFormData();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler.getJson('/ticket-historico/ticket/' + this.ticket.id + '/grid')
    .then(data => {
      if(data) component.setState({
        historia: data
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  getEventoDisplay (evento) {
    switch (evento) {
      case 'TICKETS_CREAR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_creacion_del_ticket', defaultMessage: 'Creación del Ticket' }), iconClass: 'la la-dot-circle-o', iconColor: ''};
      case 'TICKETS_ASIGNAR_GERENCIADOR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_gerenciador', defaultMessage: 'Asignación de Gerenciador' }), iconClass: 'la la-suitcase', iconColor: ''};
      case 'TICKETS_ASIGNAR_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_taller', defaultMessage: 'Asignación de Taller' }), iconClass: 'la la-wrench', iconColor: ''};
      case 'TICKETS_CREAR_COMENTARIO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_ingreso_de_comentario', defaultMessage: 'Ingreso de Comentario' }), iconClass: 'la la-comments', iconColor: ''};
      case 'TICKETS_CREAR_PRESUPUESTO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_nuevo_presupuesto', defaultMessage: 'Nuevo Presupuesto' }), iconClass: 'la la-file-text', iconColor: ''};
      case 'TICKETS_EDITAR_PRESUPUESTO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_edicion_presupuesto', defaultMessage: 'Edición de Presupuesto' }), iconClass: 'la la-file-text', iconColor: ''};
      case 'TICKETS_ASIGNAR_TURNO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_turno', defaultMessage: 'Asignación de Turno' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_RE_ASIGNAR_TURNO':
        return {txt: this.props.intl.formatMessage({ id: 'Cambio_de_turno_-_Re-agendamiento', defaultMessage: 'Cambio de turno - Re-agendamiento' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_ESTADO_MODIFICAR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_cambio_de_estado', defaultMessage: 'Cambio de Estado' }), iconClass: 'la la-check-circle', iconColor: ''};
      case 'TICKETS_ASIGNAR_FCH_REALIZADO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_fecha_de_realizado', defaultMessage: 'Asignación fecha de realizado' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_ASIGNAR_PROMETIDA':
          return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_fecha_prometida', defaultMessage: 'Asignación fecha prometida' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_ASIGNAR_FCH_INICIO_REP':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_fecha_inicio_reparacion', defaultMessage: 'Asignación fecha de inicio de reparacion' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_ASIGNAR_FCH_FIN_REPARA':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_fecha_fin_reparacion', defaultMessage: 'Asignación fecha fin de reparacion' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_INFORMAR_FACTURA':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_carga_de_factura', defaultMessage: 'Carga de Factura' }), iconClass: 'la la-inbox', iconColor: ''};
      case 'TICKETS_ENTRADA_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_entrada_a_taller', defaultMessage: 'Entrada a taller' }), iconClass: 'la la-sign-in', iconColor: ''};
      case 'TICKETS_SALIDA_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_salida_del_taller', defaultMessage: 'Salida del taller' }), iconClass: 'la la-sign-out', iconColor: ''};
      case 'TICKETS_ENTRADA_TALLER_VERIFICADA_GPS':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_entrada_del_taller_verificada_por_GPS', defaultMessage: 'Entrada del taller verificada por GPS' }), iconClass: 'la la-satellite-dish', iconColor: ''};
      case 'TICKETS_SALIDA_TALLER_VERIFICADA_GPS':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_salida_del_taller_verificada_por_GPS', defaultMessage: 'Salida del taller verificada por GPS' }), iconClass: 'la la-satellite-dish', iconColor: ''};
      case 'TICKETS_EDICION_DETALLE':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.render.modal_history.ticket_edicion_detalle', defaultMessage: 'Detalles del ticket actualizado' }), iconClass: 'la la-satellite-dish', iconColor: ''};

      default:
        return {txt: '', iconClass: '', iconColor: '#ccc'};
    }
  }

  getValuesTicketEstadoModificar(detalle, ticketEstado){
    let nombreMotivoCancelacion = "";
    let tieneMotivoCancelacion = false;
    if(this.props.ticket.motivoCancelacion && detalle[1] == 'CANCELADO'){
      if(detalle[3] && detalle[4]){
        if(this.props.ticket.motivoCancelacion.multilenguaje_id){
          let multilenguaje_id = this.props.ticket.motivoCancelacion.multilenguaje_id;
          nombreMotivoCancelacion = this.props.intl.formatMessage({...(backendStrings[multilenguaje_id] ? backendStrings[multilenguaje_id] : backendStrings['errors.default']) })
          tieneMotivoCancelacion = true;
        }else{
          for (let index = 5; index < detalle.length; index++) {
            nombreMotivoCancelacion += detalle[index];
            if(index !== (detalle.length - 1)){
              nombreMotivoCancelacion += " ";
            }
          }
          if(nombreMotivoCancelacion)
            tieneMotivoCancelacion = true;
        }
      }
    }
    if(tieneMotivoCancelacion && nombreMotivoCancelacion){
      return (
        <>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.estado_CANCELADO'
            defaultMessage="Estado: {estado} - Motivo cancelación: {nombreMotivoCancelacion}"
            values={{estado:ticketEstado[detalle[1]], nombreMotivoCancelacion: nombreMotivoCancelacion}}/>
        </>
      );
    }else{
      return (
        <>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.estado'
            defaultMessage="Estado: {estado} "
            values={{estado:ticketEstado[detalle[1]]}}/>
        </>
      );
    }
  }

  getDetalleDisplay (item) {
    const detalle = item.detalle.split(' ')
    const ticketEstado = localStorage.getItem('ticketsEstadoStrings') ? JSON.parse(localStorage.getItem('ticketsEstadoStrings')) : []
    switch (item.evento) {
      case 'TICKETS_ASIGNAR_GERENCIADOR':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignacion_de_gerenciador'
            defaultMessage='Gerenciador: {nombre}'
            values={{nombre:item.detalle.substr(12, item.detalle.length)}}/>
          </>);
      
      case 'TICKETS_ASIGNAR_TALLER':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignacion_de_taller'
            defaultMessage='Taller: {nombre}'
            values={{nombre:item.detalle.substr(7, item.detalle.length)}}/>
          </>);
      
      case 'TICKETS_CREAR_PRESUPUESTO':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.nuevo_presupuesto'
            defaultMessage='Nombre: {nombre} / Mano de Obra: {manoDeObra} / Repuestos: {repuestos} / Adicional: {adicional}'
            values={{nombre:detalle[1], manoDeObra: detalle[6], repuestos: detalle[9], adicional: detalle[12]}}/>
          </>);
      
      case 'TICKETS_EDITAR_PRESUPUESTO':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.edicion_presupuesto'
            defaultMessage='Nombre: {nombre} / Mano de Obra: {manoDeObra} / Repuestos: {repuestos} / Impuestos: {impuestos} / Otros: {otros} / Adicional: {adicional}'
            values={{nombre:detalle[1], manoDeObra: detalle[6], repuestos: detalle[9], impuestos: detalle[12], otros: detalle[15], adicional: detalle[18]}}/>
          </>);
      
      case 'TICKETS_ASIGNAR_TURNO':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignar_turno'
            defaultMessage='Turno: {fecha} {hora}'
            values={{fecha:detalle[1], hora:detalle[2]}}/>
          </>);
      
      case 'TICKETS_RE_ASIGNAR_TURNO':

        let nombre = '';
        for (let index = 8; index < detalle.length; index++) {
          nombre += detalle[index];
          if(index !== (detalle.length - 1)){
            nombre += " ";
          }
        }
        return (<>
          <FormattedMessage
            id='Nuevo_Turno'
            defaultMessage='Nuevo Turno: {fecha} {hora} - Motivo del cambio: {nombre}'
            values={{fecha:detalle[2], hora:detalle[3], nombre:nombre}}/>
          </>);

      case 'TICKETS_ESTADO_MODIFICAR':
        return this.getValuesTicketEstadoModificar(detalle, ticketEstado);
      
      case 'TICKETS_ASIGNAR_FCH_REALIZADO':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignar_fecha_realizado'
            defaultMessage="Fecha Realizado: {fecha} {hora} - Km en fecha de realizado: {km} "
            values={{fecha:detalle[2], hora:detalle[3], km: item.detalle.substr(item.detalle.indexOf('realizado:')+('realizado:'.length)) }}/>
        </>);
      
      case 'TICKETS_ASIGNAR_PROMETIDA':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignar_prometida'
            defaultMessage='Prometida: {fecha}'
            values={{fecha:detalle[1]}}/>
          </>);
      
      case 'TICKETS_ASIGNAR_FCH_INICIO_REP':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignar_fecha_inicio_reparacion'
            defaultMessage="Fecha Inicio Reparacion: {fecha} {hora}"
            values={{fecha:detalle[3], hora:detalle[4]}}/>
        </>);
      
      case 'TICKETS_ASIGNAR_FCH_FIN_REPARA':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.asignar_fecha_fin_reparacion'
            defaultMessage="Fecha Fin de Reparacion: {fecha} {hora} - Medidor: {km}"
            values={{fecha:detalle[4], hora:detalle[5], km:detalle[8]}}/>
        </>);
      
      case 'TICKETS_INFORMAR_FACTURA':
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.informar_factura'
            defaultMessage='Factura: {factura}'
            values={{factura:detalle[1]}}/>
          </>);
      
      case 'TICKETS_ENTRADA_TALLER':
        return this.props.intl.formatMessage({ id: 'ticketHistorico.detalleDisplay.label_entrada_a_taller', defaultMessage: 'Entrada a taller' });
      
      case 'TICKETS_SALIDA_TALLER':
        if(ConfigBusiness.get('tickets.informaFechaSalidaTaller.habilitado') === 'false')
          return this.props.intl.formatMessage({ id: 'ticketHistorico.detalleDisplay.salida_de_taller', defaultMessage: 'Salida de taller' });
        
        return (<>
          <FormattedMessage
            id='ticketHistorico.detalleDisplay.salida_de_taller_informada'
            defaultMessage="Fecha Salida de taller informada: {fecha} {hora}"
            values={{fecha:detalle[5], hora: detalle[6] }}/>
        </>);
      
      case 'TICKETS_ENTRADA_TALLER_VERIFICADA_GPS':
        return this.props.intl.formatMessage({ id: 'ticketHistorico.detalleDisplay.label_entrada_a_taller_verificada_gps', defaultMessage: 'Entrada a taller verificada por gps' });
        
      case 'TICKETS_SALIDA_TALLER_VERIFICADA_GPS':
        return this.props.intl.formatMessage({ id: 'ticketHistorico.detalleDisplay.label_salida_a_taller_verificada_gps', defaultMessage: 'Salida de taller verificada por gps' });
      

      default:
        return item.detalle;
    }
  }

	render() {
    if(this.ticket) {
      return (
        <React.Fragment>
          {this.state.loading && <Loading />}
          <div className="content-wrapper">
            <div className="content-body">
              <section id="timeline" className="timeline-left timeline-wrapper">
                <ul className="timeline">
                  <li className="timeline-line"></li>
                  { this.state.historia.map((item, index)=>{
                      let persona = item.persona ? item.persona.nombre + ' ' + item.persona.apellido : this.props.intl.formatMessage({ id: 'ticketHistorico.render.modal_history.text_generado_por_el_sistema', defaultMessage: 'Generado por el sistema' });
                    return(
                      <li key={index} className={item.evento === 'TICKETS_ESTADO_MODIFICAR' ? "timeline-item status-change" : "timeline-item"}>
                        <div className="timeline-badge">
                          <span className="bg-fleet bg-lighten-1" data-toggle="tooltip" data-placement="right">
                            <i className={ this.getEventoDisplay(item.evento).iconClass }></i>
                          </span>
                        </div>
                        <div className="timeline-card card border-grey border-lighten-2 card-history">
                          <div className="card-header">
                            <h4 className="card-title">{ moment(item.fechaHora).format('L') + ' - ' + this.getEventoDisplay(item.evento).txt + ' - ' + persona }</h4>
                            <p className="card-subtitle text-muted pt-1">
                              {/* <span className="font-small-3">{ item.detalle }</span> */}
                              <span className="font-small-3">{ this.getDetalleDisplay(item) }</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketHistorico);